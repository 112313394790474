import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import { GatsbySeo } from "gatsby-plugin-next-seo"

const Tokyo2 = () => {
  function changeSnd(sound) {
    document.getElementById("snd").pause()
    document
      .getElementById("snd")
      .setAttribute(
        "src",
        `https://spanish.ecc.u-tokyo.ac.jp/aries/amorvida/mp3${sound}`
      )
    document.getElementById("snd").load()
    document.getElementById("snd").play()
  }

  return (
    <Layout>
      <GatsbySeo
        title="Universidad de Tokyo"
        titleTemplate="%s | Eduardo Ceballos"
        description=""
        canonical="https://eduardoceballos.com/tokyo"
        openGraph={{
          url: `https://eduardoceballos.com/tokyo`,
          title: `Universidad de Tokyo`,
          description: ``,
          images: [
            {
              url: `https://eduardoceballos.com/seo/tokyo.png`,
              alt: ``,
            },
          ],
          site_name: "Eduardo Ceballos",
        }}
        twitter={{
          handle: "@PoetadeSalta",
          site: "@PoetadeSalta",
          cardType: "summary_large_image",
        }}
      />
      <h1>2. Recitación</h1>
      <h6>
        <a href="https://spanish.ecc.u-tokyo.ac.jp/aries/amorvida/index.html">
          Fuente original.
        </a>
      </h6>
      <div className="tokyo-menu-flex">
        <Link to="/tokyo">0. Presentación</Link>
        <Link to="/tokyo/1">1. Entrevista</Link>
        <Link to="/tokyo/2">2. Recitación</Link>
        <Link to="/tokyo/3">3. Equipo de producción</Link>
        <Link to="/tokyo/4">4. Agradecimientos</Link>
      </div>
      <center>
        <img
          src="https://spanish.ecc.u-tokyo.ac.jp/aries/amorvida/bmp/amorvida01.bmp"
          alt=""
        />
        <br />
        <br />
        <audio
          id="snd"
          controls
          src="https://spanish.ecc.u-tokyo.ac.jp/aries/amorvida/mp3/amorvida01.mp3"
        >
          <track default kind="captions" />
          Your browser does not support the
          <code>audio</code> element.
        </audio>
      </center>

      <div className="tokyo-menu-flex-2">
        <button
          onClick={() => changeSnd("/amorvida01.mp3")}
          onKeyPress={() => changeSnd("/amorvida01.mp3")}
          tabIndex="0"
        >
          (1) ¡Hola!
        </button>
        <button
          onClick={() => changeSnd("/amorvida02.mp3")}
          onKeyPress={() => changeSnd("/amorvida02.mp3")}
          tabIndex="0"
        >
          (2) El viaje...
        </button>
        <button
          onClick={() => changeSnd("/amorvida03.mp3")}
          onKeyPress={() => changeSnd("/amorvida03.mp3")}
          tabIndex="0"
        >
          (3) Hablando de intercambio...
        </button>
        <button
          onClick={() => changeSnd("/amorvida04.mp3")}
          onKeyPress={() => changeSnd("/amorvida04.mp3")}
          tabIndex="0"
        >
          (4) Hoy han crecido...
        </button>
        <button
          onClick={() => changeSnd("/amorvida05.mp3")}
          onKeyPress={() => changeSnd("/amorvida05.mp3")}
          tabIndex="0"
        >
          (5) Para tener esa visión...
        </button>
        <button
          onClick={() => changeSnd("/amorvida06.mp3")}
          onKeyPress={() => changeSnd("/amorvida06.mp3")}
          tabIndex="0"
        >
          (6) La sinrazón no debe ganar...
        </button>
        <button
          onClick={() => changeSnd("/amorvida07.mp3")}
          onKeyPress={() => changeSnd("/amorvida07.mp3")}
          tabIndex="0"
        >
          (7) Para eso está la poesía
        </button>
        <button
          onClick={() => changeSnd("/amorvida08.mp3")}
          onKeyPress={() => changeSnd("/amorvida08.mp3")}
          tabIndex="0"
        >
          (8) ¿Ama usted la vida?
        </button>
        <button
          onClick={() => changeSnd("/amorvida09.mp3")}
          onKeyPress={() => changeSnd("/amorvida09.mp3")}
          tabIndex="0"
        >
          (9) La historia humana...
        </button>
        <button
          onClick={() => changeSnd("/amorvida10.mp3")}
          onKeyPress={() => changeSnd("/amorvida10.mp3")}
          tabIndex="0"
        >
          (10) El humanismo clásico...
        </button>
      </div>

      <p>
        POR AMOR A LA VIDA es un trabajo literario que tiende a generar
        conciencia para racionalizar la administración de los terrenales
        recursos. Para ello, el autor viaja por todo el espacio de la historia
        natural, desde el Big Bang hasta el presente de climas complicados. Un
        recorrido por la materia que viene desde lejos marcando distancias
        siderales e influencia en la tierra con su carga de neutrinos.
        <br />
        Luego, el asombrado mundo de la interioridad, donde se construye la
        personalidad y el yo, el núcleo de uno mismo. En ese camino sin sombras
        compara las células con los planetas y descubre similitudes que
        emocionan.
        <br />
        Luego de caminar por el espacio y por la carne, sale por los balcones de
        sus ojos a mirar el mundo circundante en el que comparten los hombres el
        paisaje. Todo está relacionado y se contiene. <br />
        La vida se reparte por el aire, por el agua, por la tierra. Los hombres
        construyen y destruyen, matan y viven, falta un poco de orden y
        equilibrio.
        <br />
        Es necesario devolver a las próximas generaciones este habitáculo
        terrenal sano. Las contaminaciones están enfermando la tierra y producen
        cambios que provocan muerte.
        <br />
        Se trabaja para generar conciencia POR AMOR A LA VIDA.
        <br />
        <br />
        <br />
        MON&amp;OACUTE;LOGO POR AMOR A LA VIDA
        <br />
        DE EDUARDO CEBALLOS
        <br />
        <br />
        (1)
        <br />
        + ¡Hola! ¿Cómo estás? ¿Cómo ya no me conoces?
        <br />
        - Pero, ¿quién eres?
        <br />
        + Soy tu otro yo, el alter ego. Hace más de sesenta años que compartimos
        la existencia.
        <br />
        - Entonces, ¿somos la misma cosa?
        <br />
        + Efectivamente somos la misma persona... Trabajemos juntos en un
        monólogo, o un diálogo conmigo mismo, o un soliloquio, que lo quería
        ofrecer como un testimonio de mi paso por la vida...
        <br />
        - ¿Un mono qué?
        <br />
        + Un monólogo que devuelva parte de lo mucho recibido.
        <br />
        - ¿Tiene que ver con el monotributo?
        <br />
        + No. El monólogo es un caudal de recuerdos, emociones que vienen de la
        memoria, para mostrar el paisaje interior y esas personales fotos que
        uno saca con el alma de ese mundo circundante.
        <br />
        - ¿Y yo de qué juego? ¿De mono solo?...
        <br />
        + No, porque eres parte de mi mismo, así que juntos abriremos el cofre
        donde están grabados los distintos momentos de la existencia.
        <br />
        - Tu pedido es una orden. Comienza como quieras...
        <br />
        + Tomo las llaves, abro el íntimo rincón de mi yo, y desde las ventanas
        de mis ojos observo el mundo. Percibo su enfermedad y eso mueve mi
        sensibilidad y surge la necesidad de expresarme por la salud de la
        tierra, POR AMOR A LA VIDA.
        <br />
        - ¿Pero de qué está enferma la tierra?
        <br />
        + Esa es una larga historia...
        <br />
        - ¿Por qué no la cuentas?
        <br />
        + Con todas las dimensiones de mi yo escribí el libro del tiempo, la
        crónica de la existencia.
        <br />
        + Ya empiezo la tarea, pero antes de comenzar la historia natural,
        agradezco el silencio que me prestan, porque es un arte y un gesto de
        sabiduría el saber escuchar...
        <br />
        - Comienza la historia...
        <br />
        <br />
        (2)
        <br />
        + El viaje se inicia en las alturas, donde se dio la explosión inicial
        hace muchos millones de años llamada Big Bang como movimiento
        primigenio.
        <br />
        - ¿Pero eso fue así?
        <br />
        + Hoy la ciencia dice que puede regresar para ver vestigios de ese
        momento,
        <br />
        - ¿Cómo habrá sido?
        <br />
        + Ahora si tomo las llaves para abrir el cofre de los recuerdos, las
        llaves de la memoria, las del tiempo.
        <br />
        - Yo, las llaves para abrir el corazón de la gente que nos escucha.
        <br />
        + Estas llaves me llevarán en un imaginario viaje por todas las
        dimensiones de la vida. Primero mi relación con el cosmos, de donde
        vienen nuestras energías, porque la vida se nutre de estrellas. El
        recorrido abarca lo distante, lo celeste; de allí viajan las luces, los
        milagros de la vida. Ya lo siento, percibo el movimiento continuo que
        ofrece el universo. Se impone la necesidad de amar lo que nos envuelve,
        amar lo que nos rodea y abraza.
        <br />
        Busco la relación con la energía, el espacio y el tiempo.
        <br />
        Recorro la historia del universo, la formación de las galaxias. <br />
        Con asombro compruebo que nuestra galaxia tiene 300.000 millones de
        estrellas de todo tamaño.
        <br />
        Estoy maravillado, al ver que desde aquí se climatiza la tierra, desde
        estas alturas infinitas…percibo que los fenómenos de la naturaleza
        siguen reglas matemáticas <br />
        - Yo estoy maravillado porque tengo las llaves para abrir el corazón de
        quienes me escuchan. Siento que abro la comunicación con cada uno de los
        presentes para producir un puente emotivo, una luz de contacto. Estoy
        feliz por el intercambio de energía y de emociones.
        <br />
        <br />
        (3)
        <br />
        + Hablando de intercambio… Observo la magnitud del universo, el sistema
        de las galaxias, nuestra galaxia, el sistema solar, los planetas,
        nuestra tierra y toda esa realidad la relaciono con la vida y con el
        hombre.
        <br />
        - Que maravilla!
        <br />
        + Si que es una maravilla ver al homo sapiens, como un ser extraño de la
        creación, fin y rey de todo el vasto cosmos... <br />
        - Por un lado su pequeñez… no llega a ser ni un punto.
        <br />
        + Por el otro, su mente, ya que gracias a ella, a los sucesos de nuestra
        propia vida, se conoce el mundo. Para la mente humana nada es demasiado
        grande o demasiado pequeño, para que su intelecto lo albergue o abarque.
        Nada es demasiado distante en el tiempo o el espacio, para que se le
        asigne su debido peso en la estructura del cosmos.
        <br />
        - Ante la inmensidad del mundo, la pequeñez humana, ante los tiempos
        siderales, la edad del hombre...
        <br />
        + A pesar de todo el hombre mortal, imperfecto, finito, escarba con sus
        ciencias y avanza con sus conocimientos.
        <br />
        - ¿El saber cósmico es una observación profunda de lo físico y lo
        metafísico?
        <br />
        + Es muy difícil establecer los límites. La meteorología, por ejemplo,
        es la ciencia de los fenómenos atmosféricos, extrae información de todo
        lo que está elevado.
        <br />
        La atmósfera, masa gaseosa que envuelve la tierra, da las condiciones
        que producen los climas.
        <br />
        Miro la tierra que gira velozmente y la transparente temperatura
        sideral, como una intensa danza de movimiento continuo.
        <br />
        - ¿Cuanto dura el viaje del cosmos? ¿Cuál es la edad del universo?
        <br />
        + El tiempo que dura el cosmos es un tiempo sideral, otro tiempo,
        enmarcado en otras medidas, lo mismo que la edad. Pero ante tanta
        grandeza y por las influencias sobre la tierra, se puede afirmar como
        reflexión que la patria es todo el mundo, ya que nada de lo que sucede,
        puede resultar ajeno a la existencia humana.
        <br />
        - Entonces la astronomía ¿tiene que ver con el hombre?
        <br />
        + Efectivamente, la astronomía es la más antigua de las ciencias, la que
        dominaba Anaxágoras, en los tiempos en que el hombre miraba el mundo
        circundante, con placer, con deseo de conocerlo y comprenderlo.
        <br />
        Así como el estudio del universo viene de lejos en el tiempo, el cuerpo
        humano fue tratado como materia de estudio por Hipócrates en la época de
        Pericles, en la Grecia clásica, que creció como cultura, porque había
        ausencia de moldes rígidos y esto permitió el desarrollo y el uso de la
        libertad de criterio. Por ese motivo han prosperado las artes, las
        ciencias, los espectáculos teatrales.
        <br />
        <br />
        (4)
        <br />
        - Hoy han crecido los celulares y se han llevado el asombro.
        <br />
        + Recién recorrimos el cosmos y ahora avanzamos hacia la compleja
        interioridad del hombre, en un viaje por el río de la sangre, navegando
        por la vida en el mapa de mi cuerpo.
        <br />
        La existencia humana transcurre entre dos eternidades, de silencioso
        misterio.
        <br />
        Con los jugos que conforman mi ser, muevo toneladas de emociones y cada
        gota me vincula con el agua y la lluvia, ya que la sangre guarda la
        secreta identidad personal.
        <br />
        Visito mis veredas interiores por esos caminos sin sombra en el cauce de
        mi sangre y percibo como el cerebro controla este universo - cuerpo a
        través de las neuronas. En el hemisferio izquierdo está el lenguaje y en
        el derecho la percepción espacial, el contacto con el mundo. En su
        espacio se almacena la inteligencia, la personalidad, la voluntad, la
        sensibilidad.
        <br />
        En el espacio interior está el mapa genético, el libro de la vida, que
        controla el desarrollo con toda la memoria.
        <br />
        La circulación sanguínea me lleva hasta los músculos y cuando veo su
        trabajo, pregunto por la voluntad. <br />
        - ¿Qué es la voluntad? ¿Una decisión, un deseo, un fuego o un gesto?
        <br />
        + La característica de una persona, porque en el cuerpo humano casi todo
        lo que lo conforma es único, personal, individual. Que hermosura el
        cuerpo humano cuya estructura está en el esqueleto, en los huesos, que
        están conformados por células, y estas por moléculas y estas por átomos
        y estos por neutrones en un viaje de misterio hacia lo más pequeño.
        <br />
        Ahora, ingreso por la oreja y descubro que en su interior trabaja el
        equilibrio.
        <br />
        Desde mi cuerpo percibo el movimiento de las galaxias, de los paisajes,
        de la sangre y del agua. Emerjo al nivel de la tierra y veo toda su
        historia y descubro que la naturaleza tiene belleza y armonía.
        <br />
        <br />
        (5)
        <br />
        - Para tener esa visión, el hombre debe ser acróbata y saber andar por
        las puntas, por las cumbres, observando todo, pero sin comprometerse con
        ninguna teoría.
        <br />
        + Justamente, esa fue la virtud principal del pueblo griego, que ha
        crecido en saberes como ningún otro.
        <br />
        Sócrates, con su célebre frase "solo sé que no se nada", ha transferido
        su inquietud a su discípulo Platón y a través de él a toda la humanidad,
        desde aquel tiempo. Platón, que enseñaba en la casa de Academos, por el
        método socrático denominado mayéutica, porque la búsqueda de la verdad
        la comparaba con un parto. Platón había formado una sociedad científica
        literaria, que ha dejado fuertes señales a toda la humanidad.
        <br />
        Aristóteles, discípulo de Platón, aportó su saber sobre muchas
        disciplinas que hasta hoy tienen un alto valor intelectual. Enseñaba en
        el liceo, que poseía a su alrededor un paseo circular llamado Peripato y
        como caminaba por ese circuito enseñando su método, se lo llamó
        Peripatético. Aristóteles entre las obras que dejó, un tratado de
        Metafísica, que en griego comenzaba con: metá tá fisicá, son las
        primeras tres palabras de esta ciencia de los principios primeros y
        fundamentales. Aristóteles nivelaba la poesía con la filosofía y la
        historia; a propósito expresaba: la historia cuenta lo que sucedió, la
        poesía, lo que debía suceder. Desde ese tiempo y de mucho tiempo atrás,
        el hombre ha dejado fuertes señales. Por ejemplo está comprobado en la
        zona de Egipto, hace cien siglos, el hombre llevaba el agua adonde
        faltaba.
        <br />
        - Entonces, hay que trabajar para crear un mundo nuevo sobre la base del
        viejo. Este trabajo nos compromete a todos. <br />
        Si no se trabaja, lo que prestó el futuro será devuelto en malas
        condiciones. Es difícil comprender la maravilla del mundo, pero mucho
        más difícil es transformarlo.
        <br />
        <br />
        (6
        <br />
        + La sinrazón no debe ganarle a lo lógico; es menester que lo importante
        supere a la vanalidad. En la tierra están los recursos, los alimentos,
        la química, el asombro y la semilla. Hay que fundamentar la soberanía de
        la vida y establecer los límites. La propiedad es una porción de tierra,
        que es parte de un todo. Los ríos fundan lo verde, transportan el caudal
        que alimenta y dibujan progreso y alegría. Para todo hombre hay un
        camino abierto hacia la luz. Se debe privilegiar la salud de la tierra,
        aunque perjudique algunos negocios, POR AMOR A LA VIDA.
        <br />
        - Asombrarnos será el primer paso hacia el descubrimiento.
        <br />
        La biofísica, es la ciencia que estudia la naturaleza de los seres vivos
        en un complejo listado de existencias diversas y la bioquímica estudia
        los fenómenos químicos en el ser vivo, donde aparece el movimiento de la
        materia.
        <br />
        + Para transformar el mundo, para avanzar en el mejoramiento de su
        salud, se debe consultar a todas las ciencias, para que cada una aporte
        su saber, como la agronomía que guarda en sus saberes la ley del campo,
        o el conocimiento de la tierra.
        <br />
        - Y pensar que esa tierra, nuestra tierra, está directamente relacionada
        con el cosmos, de quien recibe permanentemente sus energías.
        <br />
        + Efectivamente la tierra con todo lo que recibe del espacio se
        convierte en alquimista, ya que ingresa una semilla y devuelve un tomate
        o una pera. Esto nos hace recordar a los alquimistas de la edad media,
        que a fuerza de mezclar jugos químicos, pretendían cosechar oro. Pero no
        es tan fácil imitar el laboratorio natural que trabaja en la tierra para
        producir el oro o el diamante.
        <br />
        Hablando del diamante, se lo llama el indomable, la más dura de las
        piedras, en la que invierte millones de años para su hechura.
        <br />
        - Es menester estudiar la casa, la tierra, a través de la ecología, para
        evitar el caos que producen los ingredientes químicos que se arrojan a
        la tierra y la economía debe acompañar al conocimiento en el gobierno de
        la casa.
        <br />
        + La geometría también ayuda, porque a través de ella se conoce las
        formas de la naturaleza. Por la física se profundiza en la materia, que
        está compuesta de partículas que persisten en el tiempo y que cambian de
        posición en el espacio, ya que están en permanente movimiento.
        <br />
        La enfermedad de la tierra y de los hombres, se confunde en una sola
        molécula. En la filosofía está el bien y el mal; en la química, la vida
        y la muerte; en la mente humana, la idea de la paz y de la guerra.
        <br />
        - Por lo tanto, debemos demostrar que estamos despiertos, que no estamos
        en el cementerio, que es el lugar de los que duermen. Despiertos pero
        con sueños, que rimen con la vida y se midan con la lluvia. Se debe
        producir un abrazo cultural, científico con la vida, para inventar una
        herramienta de comunicación que nos emparente con la realidad
        circundante.
        <br />
        <br />
        (7)
        <br />
        + Para eso está la poesía que incluye lo físico, lo químico, la vida. La
        finalidad del arte es dar cuerpo a la esencia secreta de las cosas. El
        saber dudar a tiempo es parte del crecimiento, porque el que no duda, no
        conoce cosa alguna. La duda es humana y edifica. La verdad está en
        movimiento y las leyes del movimiento son las mismas para toda la
        materia. El conocimiento es un caso de creencia verdadera, ya que no hay
        verdad existencial demostrable.
        <br />
        Dos creencias no pueden ser ambas verdaderas. Las creencias implican
        datos, causas, premisas. No creo que esté soñando ahora, en este
        instante, pero tampoco puedo probar que no lo estoy haciendo.
        <br />
        - Para que un movimiento pueda ser percibido, no debe ser demasiado
        rápido, ni demasiado lento.
        <br />
        + Efectivamente, ¡qué lindo sería percibir cuando la música, que nace en
        el silencio de nuestra anatomía, y que también está en los ríos, en los
        vientos y anda con la vida! Como todo es movimiento, la palabra no es la
        excepción, reemplaza el ruido por música deleitable. La palabra de a
        ratos, tiene la precisión de una balanza o un termómetro y en otro
        momento no hay palabras cuyo significado sea preciso, ni siquiera
        centímetro o segundo.
        <br />
        La palabra viene de lejos, cruzando fronteras, ocupando geografías,
        arrastrando memorias de culturas.
        <br />
        - La idea es hacer un inventario para comprender mejor el mundo y con
        criterio analizar el peso, la masa, la densidad, la gravedad, la
        temperatura, el volumen, la forma, la sustancia. Analizar lo que nos
        circunda y meter esa realidad en una idea.
        <br />
        + Puedo decir que el hombre perdió la inocencia, pero conserva intacta
        su ingenuidad. A propósito, gracias al sublime candor de la infancia que
        calca imágenes, tengo las fotografías sacadas con el alma, que me
        cuentan del cambio de los paisajes.
        <br />
        La memoria, como un museo que guarda las obras de arte que inspiraron
        las musas, va sacando las visiones de la tierra que asustan por tanta
        muerte.
        <br />
        Como será de importante el clima, que dibuja la inclinación del eje de
        la tierra. Si la temperatura media varía hacia arriba o hacia abajo, en
        pocos grados, pondría en riesgo de extinción a muchas especies vivas de
        la tierra.
        <br />
        - Desde el espacio viene la energía, que es obra, acción y movimiento.
        El hombre mide la trayectoria de los astros y desconoce la propia.
        Conoce el átomo, pero no su pensamiento. Como animal que somos, poseemos
        un soplo, un espíritu que se lo ubica entre la carne.
        <br />
        <br />
        (8)
        <br />
        + A ver, amigo, ¿ama usted la vida?, ¿Qué ha hecho de su existencia?,
        ¿Tuvo una finalidad superior? ¿O fue un cúmulo de miserias? Que bella y
        compleja la existencia humana. La voluntad de vivir, viene del verbo
        latino "volo" que significa querer, pero no siempre se puede lo que se
        quiere. El mundo castiga a los que hacen lo que les da la gana con
        ostentación.
        <br />
        El precio de la libertad, compare su casa y la mente. En su casa nadie
        toca sus pertenencias, porque usted no lo permite. Su mente, es la casa
        de sus ideas, no debe permitir que ningún intruso se apodere de sus
        pertenencias. Trabaje para sostener su individualidad. La hipocresía es
        el homenaje que el vicio rinde a la virtud. La ética y la moral, ambas
        se originan en las costumbres. Es menester pensar la realidad y darla
        vueltas por su mente para descubrir el sentido.
        <br />
        Primero superar una hipótesis para desarrollar una tesis, para
        enfrentarla a una antítesis y que desde allí surja una síntesis llena de
        luz, que explique los secretos de la existencia.
        <br />
        - Entre los datos irracionales de este tiempo, la cifra de 610.000
        millones de dólares que se ha invertido en la guerra de IRAK, ¡qué bien
        podría resolver el funcionamiento de todos los hospitales de la tierra!
        <br />
        La industria más pesada y agresiva no mata tanto como la guerra, que
        moviliza más dinero que hospitales y escuelas de todo el mundo.
        <br />
        + La necesidad es la madre de la invención y la demora es la gran
        ladrona del tiempo. La imaginación, la memoria, el deseo, el pensamiento
        y la creencia suponen ideas. Hablando de ideas, se puede aplicar una
        técnica para ir profundizando los saberes. Para ello partimos de la
        afirmación que la inteligencia humana tiende hacia lo verdadero, pero
        como es imperfecta no consigue llegar a la verdad, porque se queda en el
        camino que ofrece como una escalera por la que sube el pensamiento.
        <br />
        Cuando no se avanza, estamos en la ignorancia, que no conoce lo
        verdadero ni lo que es, como si no hubiese una idea. En cambio, la duda,
        ya empieza a percibir lo verdadero como posible. La opinión, peldaño
        superior, se funda en razones.
        <br />
        La creencia asoma cuando se maneja información, pero sin poseer todos
        los argumentos. Por ejemplo, cuando está la pregunta ¿cómo sabes de San
        Martín o Napoleón? La gente responde, Me lo dijo la maestra... se llega
        al momento en que se define la creencia, como última salida o recurso.
        <br />
        En un peldaño más arriba en la escalera del conocimiento está la
        certeza, que se comprueba en lo físico por los sentidos; a través de la
        metafísica o de la reflexión intelectual, el entendimiento; y la moral,
        por el consentimiento universal.
        <br />
        La evidencia, en lo más alto, puede ser intuitiva o discursiva,
        inmediata o mediata, una inclinación sincera y pura hacia la verdad.
        <br />
        <br />
        (9)
        <br />
        - La historia humana aparece en cada palabra. ¿Dónde está el centro del
        mundo? ¿En Oriente u Occidente?
        <br />
        El crecimiento debe buscar la felicidad en todo el tramo de la
        existencia. Cuando llegue la muerte se hará silencio y se vaciará de
        aguas, para arrojarlas en el océano del misterio, porque se deja de ser
        inquilino de la vida.
        <br />
        + El viaje de la palabra y el pensamiento en el tiempo. <br />
        Luego de Sócrates y Platón, aparece Aristóteles como uno de los primeros
        filósofos de la Jonia, se muestra como uno de los físicos antiguos, que
        se proponía clasificar los elementos del universo: agua, aire, tierra,
        fuego. Le ponía las bases a la ciencia fundamental. En la filosofía
        estaba la teoría que abarcaba la matemática, la física y la historia
        natural; en la práctica que incluía la moral, la economía doméstica, la
        economía política; y la poética, era el capítulo de la belleza,
        hermanado con la ciencia.
        <br />
        Por eso trato de unir ideas científicas con belleza clásica, poniéndole
        ingredientes a la ambigua frontera entre el relato histórico y la
        ficción.
        <br />
        - El hombre invirtió muchos milenios de búsqueda en el cielo, para
        descubrir regularidades aritméticas, para predecir inundaciones,
        terremotos, plagas…
        <br />
        El mundo está dividido en dos, en el norte, el frío del poder y en el
        sur, el caliente asiento de la rebeldía, donde está el futuro. Un mundo
        dividido en dos como un cuerpo, en el norte la cabeza y en el sur, el
        sexo.
        <br />
        + Ahora analizo como la técnica se enlaza perfectamente con los grandes
        temas filosóficos tradicionales. Los avances tecnológicos han puesto en
        jaque la imagen del hombre y de la naturaleza.. Hay que pensar al hombre
        y la naturaleza relacionados. La técnica, como transformación del hombre
        y de la naturaleza, parece escapar del dominio del propio hombre.
        <br />
        La técnica actual es inmensamente operativa, de allí los esfuerzos del
        hombre por reivindicar su propia humanidad frente a la máquina.
        Cualquier avance en cualquier lugar repercute inmediatamente en todo el
        mundo.
        <br />
        La técnica se ha transformado en tecnología, esto es, en un logos, un
        discurso, que justifica de modo ideológico el imperio mismo de la
        técnica como avance material y legitimación autorreferencial de ese
        avance.
        <br />
        La computadora es la manifestación material de la idea de un cerebro
        artificial. Se puede decir que la cibernética ha iniciado un tipo de
        relación inmediata entre lo humano, lo natural y lo artificial.
        <br />
        <br />
        (10)
        <br />
        El humanismo clásico ha sido siempre un acompañante falaz de la
        filosofía. Ninguna filosofía ha sido humanista, si entendemos por
        humanista una concepción en la que el hombre es el sujeto y el
        controlador del universo.
        <br />
        - Todo saber explica el espacio con quien se comparte la existencia, con
        quien se tiene una coexistencia y da una idea del tiempo que muestra una
        sucesión de hechos. Por ejemplo, el polvo es una unidad física mínima,
        que puede captar el ojo humano, su implicancia es percibida por
        científicos, amas de casa, fabricantes de aspiradoras, obreros,
        astrónomos, artistas, estudiosos y lingüistas pícaros. También están los
        miedos líquidos, que lo percibe el cuerpo con toda la sangre.
        <br />
        + Lo importante es que estamos aquí, pero cuando digo aquí, me refiero a
        un centro espacio - temporal, al universo, aquí en la vía Láctea, aquí
        en el sistema solar, aquí en la tierra, aquí en el continente americano,
        aquí en la República Argentina, aquí en Salta, aquí en la calle Urquiza,
        aquí en el Cine - Teatro Opera, aquí en el cerebro.
        <br />
        Lo importante es que se comparte el espacio con mucha vida, por ejemplo
        las hormigas, que son todo orden y trabajo y que cuentan con dos
        estómagos, el personal y el social para compartir alimentos.
        <br />
        También se comparte con el macho gallo, que vive en el gallinero, con su
        pinta de siempre, como una pintura natural… Y la flor que vive un juego
        amoroso e inteligente, ya que en su reproducción participa el viento,
        los insectos, los pájaros y el agua. Todo se relaciona, las plantas
        representan la base de la cadena alimentaria y juega con la luz una
        danza vital que transforma las energías en alimento. Además la luz que
        viaja a 300.000 kilómetros por segundo, sirve para medir las distancias
        de los cuerpos en el espacio.
        <br />
        Al igual que la luz, el agua se mueve y guarda en sus cavidades el
        secreto de la vida.
        <br />
        El hombre con su apetencia provocó la revolución industrial, que con sus
        maquinas modificó el clima de la tierra. Debido a esos cambios
        climáticos las nieves de Kilimanjaro se esfuman, los glaciares
        retroceden, el planeta se derrite, y se lo ve en los Alpes, en la
        Patagonia, en nuestro Perito Moreno, en Los Andes; del Ártico a la
        Antártida el planeta se degrada.
        <br />
        El hombre deberá urgente inventar una nueva ciencia que frene esta
        degradación y sane a la enferma tierra. Esa ciencia se puede llamar
        biopolítica, o terrítica; o bien plasmar una legislación que devuelva la
        salud y que sea controlada por la biocracia o la terricracia; o trabajar
        con la conciencia del humano para generar las pautas de un credo que
        hable de la biolatría, que contenga la adoración o el amor por la vida.
        <br />
        Por eso hemos trabajado, hemos establecido este puente de afecto y
        entendimiento, por la tierra, por el aire, por el agua, por el fuego,
        POR AMOR A LA VIDA!
        <br />
        <br />
      </p>
    </Layout>
  )
}

export default Tokyo2
